import { Platforms } from './platforms';

export type Sale = {
  id: string;
  saleId: string;
  url: string;
  type: Platforms;
  startAt: Date;
  endAt: Date;
  name: string;
  locationId: string;
  locationName: string;
  date: string;
};

export type SaleLot = {
  carName: string;
  carModel: string;
  mileage: number;
  gearboxType: GearboxType;
  energyType: EnergyType;
  registrationDate: Date;
  carType: CarType;
  imgUrl: string | null;
  detailsUrl: string | null;

  lotId: string;
  lotNumber: number;
  sale: Sale | null;
  salePrice: number;
  sold: boolean;

  clientId: string | null;
  clientCompanyName: string | null;
  clientFirstName: string | null;
  clientLastName: string | null;
};

export enum GearboxType {
  Manual = 'Manual',
  Automatic = 'Automatic',
}
export enum EnergyType {
  Hybride = 'Hybride',
  Gaz = 'Gaz',
  Electric = 'Electric',
  Diesel = 'Diesel',
  Benzin = 'Benzin',
}
export enum CarType {
  VU = 'VU',
  VASP = 'VASP',
  CTTE = 'CTTE',
  VP = 'VP',
  VTU = 'VTU',
  QM = 'QM',
  CCTE = 'CCTE',
  DIV = 'DIV',
  MTT2 = 'MTT2',
  CYC = 'CYC',
}
