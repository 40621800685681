import styled from '@emotion/styled';

export const Image = styled.img`
  max-width: 100%;
  width: 100%;
  max-height: 115px;
  border-radius: 4px;
  object-fit: cover;
  display: block;
`;

export const SaleTypeLogo = styled.img`
  max-width: 140px;
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
  display: block;
`;
