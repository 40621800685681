import { LotsPage } from './pages/Lots';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return <LotsPage />;
}

export default App;
