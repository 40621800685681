import { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';

import { formatDate } from './utils';
import { Image, SaleTypeLogo } from './styled';
import { Props } from './types';
import { PLATFORM_TO_LOGO } from './constants';

const Lot: FC<Props> = ({
  carModel,
  carName,
  imgUrl,
  lotNumber,
  mileage,
  registrationDate,
  gearboxType,
  energyType,
  sale,
  salePrice,
}) => {
  return (
    <Box>
      <Stack direction="row" gap={4} justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <Box width="150px" borderRadius={5}>
            <Image src={imgUrl || ''} alt={carName} height="115px" width="150px" />
          </Box>

          <Stack width="400px">
            <Typography variant="body1">{carName}</Typography>
            <Typography variant="body2">{carModel}</Typography>
            <Typography variant="caption">
              {gearboxType}, {energyType}, {formatDate(registrationDate)}
            </Typography>
            <Typography variant="caption">
              {mileage} км, номер лота: {lotNumber}
            </Typography>
          </Stack>
        </Stack>

        <Typography color="primary" fontSize="18px" fontWeight="500">
          €{salePrice.toFixed(2)}
        </Typography>

        <Stack alignItems="flex-end">
          {sale !== null && (
            <Stack gap={0.7} alignItems="flex-end">
              <SaleTypeLogo src={PLATFORM_TO_LOGO[sale.type]} alt={sale.type} />

              <Stack direction="row" alignItems="center" gap={0.25}>
                <Typography variant="body2">{formatDate(sale.date)}</Typography>
                <EventIcon color="primary" />
              </Stack>

              <Stack direction="row" alignItems="center" gap={0.25}>
                <Typography variant="body2">{sale.locationName}</Typography>
                <LocationOnIcon color="primary" />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export { Lot };
