import { FilterData, FilterQuery } from '../../types';

export type Props = {
  data: FilterData | null;
  onSubmit: (filterData: Partial<FilterQuery>) => void;
  isLoading: boolean;
};

export enum Fields {
  CarName = 'carName',
  CarModel = 'carModel',
  GearboxType = 'gearboxType',
  EnergyType = 'energyType',
  MinRegistrationYear = 'minRegistrationYear',
  MaxRegistrationYear = 'maxRegistrationYear',
  MinMileage = 'minMileage',
  MaxMileage = 'maxMileage',
  LotNumber = 'lotNumber',
  LotsLimit = 'lotsLimit',
  OnlySold = 'onlySold',
  MaxSoldDaysAgo = 'maxSoldDaysAgo',
  SaleId = 'saleId',
  ExcludeHS = 'excludeHS',
}
