import { FC } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

import { Props } from './types';

const AverageSalePrice: FC<Props> = ({ value, maxSoldDaysAgo, lotsCount }) => {
  if (value === null) {
    return null;
  }

  return (
    <Box bgcolor="#f5f5f5" borderRadius={2} padding={2}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Box padding={1} borderRadius={2} bgcolor="#fff">
          <PaymentsOutlinedIcon sx={{ color: '#000' }} fontSize="large" />
        </Box>

        <Stack direction="column" justifyContent="center">
          <Typography variant="h4" color="primary">
            €{value.toFixed(2)}
          </Typography>
          <Typography variant="body2">
            Средняя цена за последние <strong>{maxSoldDaysAgo}</strong> дней на основе{' '}
            <strong>{lotsCount}</strong> лотов
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export { AverageSalePrice };
