import { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import { PageLayout } from '../../layout/PageLayout';
import { APIService } from '../../services/api';

import { FilterData, FilterQuery, Props, SalesLotsResponse } from './types';
import { Filter } from './components/Filter';
import { filterEmptyStringFromObject } from '../../utils';
import { SaleLot } from '../../types/sales';
import { AverageSalePrice } from './components/AverageSalePrice';
import { LotsList } from './components/LotsList';

const LotsPage: FC<Props> = () => {
  const [filterData, setFilterData] = useState<FilterData | null>(null);
  const [averageSalePrice, setAverageSalePrice] = useState<number | null>(null);
  const [filterQuery, setFilterQuery] = useState<Partial<FilterQuery> | null>(null);
  const [lots, setLots] = useState<SaleLot[] | null>(null);
  const [lotsCount, setLotsCount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLotsLoading, setIsLotsLoading] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const containerHeight = useRef<number | null>(null);
  const isContainerObserved = useRef(false);

  const fetchFilterData = useCallback(async () => {
    try {
      setIsLoading(true);

      const nextFilterDataResponse = await APIService.get<FilterData>('/sales/filter');
      setFilterData(nextFilterDataResponse.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchLots = useCallback(async (filter: Partial<FilterQuery>) => {
    try {
      setIsLotsLoading(true);
      setFilterQuery(filter);

      const transformedFilter = filterEmptyStringFromObject(filter);

      const lotsResponse = await APIService.post<SalesLotsResponse>(
        '/sales/filter',
        transformedFilter,
      );

      setAverageSalePrice(lotsResponse.data.averageSalePrice);
      setLots(lotsResponse.data.lots);
      setLotsCount(lotsResponse.data.countLots);
    } catch (error) {
    } finally {
      setIsLotsLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(
    (filter: Partial<FilterQuery>) => {
      fetchLots(filter);
    },
    [fetchLots],
  );

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (let entry of entries) {
          const cr = entry.contentRect;

          if (containerHeight.current === cr.height) {
            return;
          }

          containerHeight.current = cr.height;

          const eventData = {
            type: 'container_height_changed',
            height: containerHeight.current,
          };

          window.parent.postMessage(eventData, '*');
        }
      }),
    [],
  );

  useEffect(() => {
    fetchFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (containerRef.current && !isContainerObserved.current) {
    isContainerObserved.current = true;
    resizeObserver.observe(containerRef.current);
  }

  return (
    <PageLayout ref={containerRef}>
      <Filter data={filterData} onSubmit={handleSubmit} isLoading={isLoading} />

      {averageSalePrice === null && lots === null && !isLotsLoading ? null : (
        <Box mt={2} position="relative">
          <Paper elevation={2}>
            {isLotsLoading && (
              <Box position="absolute" top={0} left={0} right={0}>
                <LinearProgress />
              </Box>
            )}

            <Box padding={3}>
              <AverageSalePrice
                value={averageSalePrice}
                maxSoldDaysAgo={Number(filterQuery?.maxSoldDaysAgo ?? null)}
                lotsCount={lotsCount}
              />

              <LotsList lots={lots} />
            </Box>
          </Paper>
        </Box>
      )}
    </PageLayout>
  );
};

export { LotsPage };
