import { PropsWithChildren, forwardRef } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { Props } from './types';

const PageLayout = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children }, ref) => {
    return (
      <Container
        ref={ref}
        sx={{
          minWidth: '930px !important',
          maxWidth: '1190px !important',
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
          margin: '0 auto',
        }}
      >
        <Paper elevation={0}>
          <Box paddingTop={2} paddingBottom={2}>
            {children}
          </Box>
        </Paper>
      </Container>
    );
  },
);

export { PageLayout };
