import { FC } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { Lot } from '../Lot';

import { Props } from './types';

const LotsList: FC<Props> = ({ lots }) => {
  return (
    <Box mt={5}>
      {(lots || []).map((lot, index, arr) => (
        <Box
          mt={1}
          key={`${lot.carModel}-${lot.carName}-${lot.registrationDate}-${index}`}
        >
          <Box mb={1}>
            <Lot {...lot} />
          </Box>

          {index !== arr.length - 1 && <Divider />}
        </Box>
      ))}
    </Box>
  );
};

export { LotsList };
