import AlcopaLogo from '../../../../assets/logos/alcopa.png';

import { EnergyType, GearboxType } from '../../../../types/sales';
import { Platforms } from '../../../../types/platforms';

export const GEARBOX_TYPE_TRANSLATIONS: Record<GearboxType, string> = {
  [GearboxType.Automatic]: 'Автоматическая',
  [GearboxType.Manual]: 'Механическая',
};

export const ENERGY_TYPE_TRANSLATIONS: Record<EnergyType, string> = {
  [EnergyType.Benzin]: 'Бензин',
  [EnergyType.Diesel]: 'Дизель',
  [EnergyType.Electric]: 'Электро',
  [EnergyType.Gaz]: 'Газ',
  [EnergyType.Hybride]: 'Гибрид',
};

export const LOT_LIMIT_OPTIONS = [10, 30, 50, 100];

export const SOLD_DAYS_AGO_OPTIONS = [
  {
    value: 10,
    label: '10 дней',
  },
  {
    value: 30,
    label: '30 дней',
  },
  {
    value: 60,
    label: '60 дней',
  },
  {
    value: 90,
    label: '90 дней',
  },
];

export const PLATFORM_TO_LOGO: Partial<Record<Platforms, string>> = {
  [Platforms.Alcopa]: AlcopaLogo,
};
