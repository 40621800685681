import { FC, useCallback, useEffect, useMemo } from 'react';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import { useFormik } from 'formik';

import { Props, Fields } from './types';
import {
  ENERGY_TYPE_TRANSLATIONS,
  GEARBOX_TYPE_TRANSLATIONS,
  LOT_LIMIT_OPTIONS,
  SOLD_DAYS_AGO_OPTIONS,
  ANY_OPTION,
  FIELDS_TO_LABELS,
  LOTS_LIMIT_ALL_OPTION,
  LOTS_LIMIT_ALL_VALUE,
  BROKEN_CARS_SALE,
} from './constants';

import { FilterQuery } from '../../types';

const Filter: FC<Props> = ({ data, onSubmit, isLoading }) => {
  const formik = useFormik<Partial<FilterQuery>>({
    initialValues: {
      [Fields.CarName]: '', // TODO: this caused to a lot of noise in dev tool
      [Fields.CarModel]: '',
      [Fields.GearboxType]: '',
      [Fields.EnergyType]: '',
      [Fields.MinRegistrationYear]: '',
      [Fields.MaxRegistrationYear]: '',
      [Fields.MinMileage]: '',
      [Fields.MaxMileage]: '',
      [Fields.LotNumber]: '',
      [Fields.LotsLimit]: 100,
      [Fields.OnlySold]: false,
      [Fields.MaxSoldDaysAgo]: 90,
      [Fields.SaleId]: '',
      [Fields.ExcludeHS]: false,
    },
    onSubmit: () => {
      const values = { ...formik.values };

      if (values.lotsLimit === LOTS_LIMIT_ALL_VALUE) {
        delete values.lotsLimit;
      }

      onSubmit(values);
    },
  });

  const renderAutocompleteInput = useCallback((params: AutocompleteRenderInputParams) => {
    const { id } = params;
    return <TextField {...params} label={FIELDS_TO_LABELS[id as Fields] ?? id} />;
  }, []);

  const handleAutocompleteChange = useCallback(
    (
      id: Fields,
      event: React.SyntheticEvent<Element, Event>,
      value: string | null,
      ...params: any
    ) => {
      formik.setFieldValue(id, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      formik.setFieldValue(event.currentTarget.id, checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const carNameOptions = useMemo(
    () => (data ? data.lots.map(({ name }) => name) : []),
    [data],
  );

  const carModelOptions = useMemo(
    () =>
      (data && data.lots.find(({ name }) => name === formik.values.carName)?.models) ||
      [],
    [data, formik.values.carName],
  );

  const gearboxTypeOptions = useMemo(
    () => [
      <MenuItem key={ANY_OPTION.value} value={ANY_OPTION.value}>
        {ANY_OPTION.label}
      </MenuItem>,
      ...(data
        ? data.gearboxTypes.map((gearboxType) => (
            <MenuItem key={gearboxType} value={gearboxType}>
              {GEARBOX_TYPE_TRANSLATIONS[gearboxType]}
            </MenuItem>
          ))
        : []),
    ],
    [data],
  );

  const energyTypeOptions = useMemo(
    () => [
      <MenuItem key={ANY_OPTION.value} value={ANY_OPTION.value}>
        {ANY_OPTION.maleLabel}
      </MenuItem>,
      ...(data
        ? data.energyTypes.map((energyType) => (
            <MenuItem key={energyType} value={energyType}>
              {ENERGY_TYPE_TRANSLATIONS[energyType]}
            </MenuItem>
          ))
        : []),
    ],
    [data],
  );

  const maxSoldDaysAgoOptions = useMemo(
    () =>
      SOLD_DAYS_AGO_OPTIONS.map((soldDaysAgo) => (
        <MenuItem key={soldDaysAgo.value} value={soldDaysAgo.value}>
          {soldDaysAgo.label}
        </MenuItem>
      )),
    [],
  );

  const lotsLimitOptions = useMemo(
    () =>
      [
        ...(formik.values.saleId ? [LOTS_LIMIT_ALL_OPTION] : []),
        ...LOT_LIMIT_OPTIONS,
      ].map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      )),
    [formik.values.saleId],
  );

  const saleIdOptions = useMemo(() => {
    let sales: string[];
    if (!data?.sales) {
      sales = [];
    } else if (!formik.values.excludeHS) {
      sales = data.sales;
    } else {
      sales = data.sales.filter((saleId) => !saleId.includes(BROKEN_CARS_SALE));
    }

    return [
      <MenuItem key={ANY_OPTION.value} value={ANY_OPTION.value}>
        {ANY_OPTION.maleLabel}
      </MenuItem>,
      ...sales.map((saleId) => (
        <MenuItem key={saleId} value={saleId}>
          {saleId}
        </MenuItem>
      )),
    ];
  }, [data?.sales, formik.values.excludeHS]);

  useEffect(() => {
    if (formik.values.saleId?.includes(BROKEN_CARS_SALE) && formik.values.excludeHS) {
      formik.setFieldValue(Fields.SaleId, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.excludeHS]);

  useEffect(() => {
    if (!formik.values.saleId && formik.values.lotsLimit === LOTS_LIMIT_ALL_VALUE) {
      formik.setFieldValue(Fields.LotsLimit, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.saleId]);

  return (
    <Paper elevation={2}>
      <Box padding={3} position="relative">
        {isLoading && (
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 10,
              }}
            />
            <CircularProgress
              size={60}
              sx={{
                position: 'relative',
                zIndex: 11,
              }}
            />
          </Box>
        )}
        <Stack direction="row" justifyContent="space-between" gap={2} mb={2}>
          <Stack width="40%" rowGap={1.5}>
            <Autocomplete
              size="small"
              id={Fields.CarName}
              freeSolo
              value={formik.values[Fields.CarName]}
              onInputChange={(...params) =>
                handleAutocompleteChange(Fields.CarName, ...params)
              }
              renderInput={renderAutocompleteInput}
              options={carNameOptions}
              title="Test"
            />

            <Autocomplete
              size="small"
              id={Fields.CarModel}
              freeSolo
              value={formik.values[Fields.CarModel]}
              onInputChange={(...params) =>
                handleAutocompleteChange(Fields.CarModel, ...params)
              }
              renderInput={renderAutocompleteInput}
              options={carModelOptions}
            />
          </Stack>

          <Stack width="30%" rowGap={1.5}>
            <FormControl size="small">
              <InputLabel id="gearbox-type-label">
                {FIELDS_TO_LABELS[Fields.GearboxType]}
              </InputLabel>

              <Select
                labelId="gearbox-type-label"
                value={formik.values[Fields.GearboxType]}
                label={FIELDS_TO_LABELS[Fields.GearboxType]}
                name={Fields.GearboxType}
                onChange={formik.handleChange}
              >
                {gearboxTypeOptions}
              </Select>
            </FormControl>

            <Stack direction="row">
              <TextField
                size="small"
                label={FIELDS_TO_LABELS[Fields.MinRegistrationYear]}
                value={formik.values[Fields.MinRegistrationYear]}
                name={Fields.MinRegistrationYear}
                onChange={formik.handleChange}
                InputProps={{
                  sx: {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
              />
              <TextField
                size="small"
                label={FIELDS_TO_LABELS[Fields.MaxRegistrationYear]}
                value={formik.values[Fields.MaxRegistrationYear]}
                name={Fields.MaxRegistrationYear}
                onChange={formik.handleChange}
                sx={{
                  marginLeft: '-1px',
                }}
                InputProps={{
                  sx: {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                }}
              />
            </Stack>
          </Stack>

          <Stack width="30%" rowGap={1.5}>
            <FormControl size="small">
              <InputLabel id="energy-type-label">
                {FIELDS_TO_LABELS[Fields.EnergyType]}
              </InputLabel>

              <Select
                labelId="energy-type-label"
                value={formik.values[Fields.EnergyType]}
                label={FIELDS_TO_LABELS[Fields.EnergyType]}
                name={Fields.EnergyType}
                onChange={formik.handleChange}
              >
                {energyTypeOptions}
              </Select>
            </FormControl>

            <Stack direction="row">
              <TextField
                size="small"
                label={FIELDS_TO_LABELS[Fields.MinMileage]}
                value={formik.values[Fields.MinMileage]}
                name={Fields.MinMileage}
                onChange={formik.handleChange}
                InputProps={{
                  sx: {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
              />
              <TextField
                size="small"
                label={FIELDS_TO_LABELS[Fields.MaxMileage]}
                value={formik.values[Fields.MaxMileage]}
                name={Fields.MaxMileage}
                onChange={formik.handleChange}
                sx={{
                  marginLeft: '-1px',
                }}
                InputProps={{
                  sx: {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <Stack mt={2} direction="row" gap={2} alignItems="center">
          <FormControl size="small" sx={{ width: '40%' }}>
            <InputLabel id="sale-id-label">{FIELDS_TO_LABELS[Fields.SaleId]}</InputLabel>

            <Select
              labelId="sale-id-label"
              value={formik.values[Fields.SaleId]}
              label={FIELDS_TO_LABELS[Fields.SaleId]}
              name={Fields.SaleId}
              onChange={formik.handleChange}
            >
              {saleIdOptions}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '20%' }} size="small">
            <TextField
              size="small"
              label={FIELDS_TO_LABELS[Fields.LotNumber]}
              value={formik.values[Fields.LotNumber]}
              name={Fields.LotNumber}
              onChange={formik.handleChange}
            />
          </FormControl>

          <FormControl sx={{ width: '40%' }} size="small">
            <FormControlLabel
              control={
                <Checkbox
                  id={Fields.ExcludeHS}
                  checked={formik.values[Fields.ExcludeHS]}
                  name={Fields.ExcludeHS}
                  onChange={handleCheckboxChange}
                />
              }
              label={FIELDS_TO_LABELS[Fields.ExcludeHS]}
              componentsProps={{ typography: { variant: 'body2' } }}
            />
          </FormControl>
        </Stack>

        <Stack mt={2} direction="row" gap={2} justifyContent="space-between">
          <Stack width="38.8%" direction="row" gap={2}>
            <FormControl sx={{ width: '50%' }} size="small">
              <InputLabel id="lots-limit-label">
                {FIELDS_TO_LABELS[Fields.LotsLimit]}
              </InputLabel>
              <Select
                labelId="lots-limit-label"
                value={formik.values[Fields.LotsLimit]}
                label={FIELDS_TO_LABELS[Fields.LotsLimit]}
                name={Fields.LotsLimit}
                onChange={formik.handleChange}
              >
                {lotsLimitOptions}
              </Select>
            </FormControl>

            <FormControl sx={{ width: '50%' }} size="small">
              <InputLabel id="max-sold-days-ago-label">
                {FIELDS_TO_LABELS[Fields.MaxSoldDaysAgo]}
              </InputLabel>

              <Select
                labelId="max-sold-days-ago-label"
                value={formik.values[Fields.MaxSoldDaysAgo]}
                label={FIELDS_TO_LABELS[Fields.MaxSoldDaysAgo]}
                name={Fields.MaxSoldDaysAgo}
                onChange={formik.handleChange}
              >
                {maxSoldDaysAgoOptions}
              </Select>
            </FormControl>
          </Stack>

          <Stack justifyContent="flex-end" direction="row" gap={3}>
            <Button variant="outlined" onClick={() => formik.resetForm()}>
              Очистить
            </Button>

            <Button variant="contained" onClick={() => formik.handleSubmit()}>
              Показать результаты
            </Button>
          </Stack>

          {/* <FormControl size="small">
            <FormControlLabel
              control={
                <Checkbox
                  id={Fields.OnlySold}
                  checked={formik.values[Fields.OnlySold]}
                  name={Fields.OnlySold}
                  onChange={handleCheckboxChange}
                />
              }
              label={FIELDS_TO_LABELS[Fields.OnlySold]}
            />
          </FormControl> */}
        </Stack>
      </Box>
    </Paper>
  );
};

export { Filter };
